import PropTypes from 'prop-types';
import {useRouter} from 'next/router';

function PaginationSquare({handleClick, className, children, isArrow, currentPage}) {
  const router = useRouter();
  const pathParams = router.asPath.split('?')[1];
  const searchParams = new URLSearchParams(pathParams);
  searchParams.delete('page');

  // Determine the next page number based on the arrow direction or the children, "left" or "right"
  let nextNumber;
  if (isArrow === 'left') {
    nextNumber = currentPage;
  } else if (isArrow === 'right') {
    nextNumber = currentPage + 2;
  } else {
    nextNumber = children;
  }

  const pageHref = searchParams.toString()
    ? `?${searchParams.toString()}&page=${nextNumber}`
    : `?page=${nextNumber}`;

  return (
    <a
      href={pageHref}
      className={`${className} mx-2 my-2 flex cursor-pointer list-none rounded px-4 py-1.5`}
      onClick={e => {
        e.preventDefault();
        handleClick();
      }}
    >
      {children}
    </a>
  );
}

export default PaginationSquare;

PaginationSquare.propTypes = {
  // Function to handle the click event
  handleClick: PropTypes.func.isRequired,
  // Additional class names for styling
  className: PropTypes.string,
  // The content to be displayed inside the pagination square
  children: PropTypes.node.isRequired,
  // Direction of the arrow ('left' or 'right'), if applicable
  isArrow: PropTypes.string,
  // The current page number, if applicable, only used when pagination square contains arrow
  currentPage: PropTypes.number,
};

PaginationSquare.defaultProps = {
  className: '',
  isArrow: '',
  currentPage: 0,
};
